import styled from "styled-components"
import touch from "@Images/pictos/touch.png"

export const ScrollSpacer = styled.div`
  opacity: 0;
  height: 100vh;
  background-color: #0B0B0B;
`

export const BlackBackground = styled.img`
  width: ${props => props.isMobile ? "80%" : "60%"};
  position: absolute;
  left: ${props => props.isMobile ? "10%" : "20%"};
`

// DESKTOP
export const StudioCreaSignOff = styled.img`
  width: ${props => props.isMobile ? "35vw" : "12.5vw"};
  position: absolute;
  left: ${props => props.isMobile ? "25.75vw" : "38.75vw"};
  top: ${props => props.isMobile ? "13vw" : "3vw"};
`

export const StudioCreaSignOn = styled.img`
  width: ${props => props.isMobile ? "45vw" : "16.1vw"};
  position: absolute;
  left: ${props => props.isMobile ? "19.3vw" : "36.45vw"};
  top: ${props => props.isMobile ? "7.5vw" : "0.9vw"};
  &:hover{
    cursor: url(${touch}), auto;
  }
`

export const BrandingSignOff = styled.img`
  width: ${props => props.isMobile ? "38vw" : "13vw"};
  position: absolute;
  left: ${props => props.isMobile ? "24.5vw" : "38.5vw"};
  top: ${props => props.isMobile ? "78.5vw" : "17.5vw"};
`

export const BrandingSignOn = styled.img`
  width: ${props => props.isMobile ? "48vw" : "16.5vw"};
  position: absolute;
  left: ${props => props.isMobile ? "18vw" : "36.2vw"};
  top: ${props => props.isMobile ? "71.8vw" : "15.1vw"};
  opacity: 1;
  &:hover{
    cursor: url(${touch}), auto;
  }
`

export const EventSignOff = styled.img`
  width: ${props => props.isMobile ? "39vw" : "16vw"};
  position: absolute;
  left: ${props => props.isMobile ? "41.5vw" : "52.5vw"};
  top: ${props => props.isMobile ? "45.5vw" : "11.5vw"};
  transform: rotate(-1deg);
`

export const EventSignOn = styled.img`
  width: ${props => props.isMobile ? "57.4vw" : "23.3vw"};
  position: absolute;
  left: ${props => props.isMobile ? "32.3vw" : "48.9vw"};
  top: ${props => props.isMobile ? "38vw" : "8.6vw"};
  opacity: 1;
  &:hover{
    cursor: url(${touch}), auto;
  }
  transform: rotate(-1deg);
`

export const DigitalSignOff = styled.img`
  width: ${props => props.isMobile ? "41vw" : "15vw"};
  position: absolute;
  left: ${props => props.isMobile ? "28.5vw" : "26.5vw"};
  top: ${props => props.isMobile ? "107.5vw" : "28.5vw"};
  transform: ${props => props.isMobile ? "rotateZ(2deg)" : null};
`

export const DigitalSignOn = styled.img`
  width: ${props => props.isMobile ? "50vw" : "18.5vw"};
  position: absolute;
  left: ${props => props.isMobile ? "23.5vw" : "24.5vw"};
  top: ${props => props.isMobile ? "103vw" : "26.93vw"};
  opacity: 1;
  transform: ${props => props.isMobile ? "rotateZ(2deg)" : null};
  &:hover{
    cursor: url(${touch}), auto;
  }
`