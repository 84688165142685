import styled from "styled-components"
import { Theme } from "./Theme"

export const BaselineWrapper = styled.div`
position: relative;
zIndex: 1000;
top: -2px;
`

export const SkillsTextWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: 4vw;
  font-weight: 900;
  padding: 10vh 10vw;
  color: ${Theme.colors.blue};
  letter-spacing: -1px;
  line-height: 5.5vw;
  text-align: left;

  @media (max-width: 960px){
    font-size: 8vw;
    line-height: 8vw;
    padding: 3vh 3vh;
  }
`

export const BigTexte = styled.span`
  padding-top: 3rem;
  color: ${Theme.colors.red};
  font-size: 6vw;
  line-height: 6vw;
  text-transform: uppercase;
  letter-spacing: -5px;
  word-spacing: 5px;

  @media (max-width: 960px){
    font-size: 10vw;
    line-height: 10vw;
  }
`