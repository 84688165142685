import React from "react";
import styled from "styled-components";
import { Theme } from "@Styles/Theme";
import { CONF } from "../conf";

import studioCrea from "@Images/skillsScreen/studio_crea_logo.png";
import branding from "@Images/skillsScreen/branding_logo.png";
import event from "@Images/skillsScreen/event_logo.png";
import digital from "@Images/skillsScreen/digital_logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

const InfosWrapperBox = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
`;

const InfosBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
`;

const RedBox = styled.div`
  width: 30vw;
  min-height: 8vw;
  position: absolute;
  top: 35vh;
  left: -30vw;
  z-index: 2000;
  background-color: ${Theme.colors.red};
  padding-right: 80px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 50px;
  transition: left 750ms ease;

  .closeCross {
    width: 30px;
    height: 30px;
    color: #fff;
    opacity: 0.5;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 1024px) {
    width: 100vw;
    height: 100vh;
    left: -100vw;
    top: 0;
  }
`;

const InsideBox = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  p {
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }

  @media (max-width: 1024px) {
    width: 80%;
    margin: 50px auto 0 auto;
    flex-direction: column;
    align-items: center;
  }
`;

const SkillLogo = styled.img`
  width: 30%;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const TextBox = styled.div`
  padding: 0 20px;
  margin-left: 20px;
  border-left: 2px solid #fff;
  @media (max-width: 1024px) {
    border-left: none;
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
`;

export default function InfosWrapper({ skill, setInfos, infosWordings }) {
  const redBoxRef = React.useRef();

  const renderLogo = () => {
    switch (skill) {
      case "studioCreatif":
        return <SkillLogo src={studioCrea} />;
      case "branding":
        return <SkillLogo src={branding} />;
      case "event":
        return <SkillLogo src={event} />;
      case "digital":
        return <SkillLogo src={digital} />;
      default:
        return null;
    }
  };

  const leave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isMobile = window.innerWidth > 1024 ? false : true;
    redBoxRef.current.style.left = isMobile ? "-100vh" : "-30vw";
    setTimeout(() => {
      setInfos(null);
    }, 750);
  };

  React.useEffect(() => {
    redBoxRef.current.style.left = "0";
  }, []);

  const renderText = () => {
    const s = infosWordings[skill];
    return s.map((el) => <p key={el}>{el}</p>);
  };

  return (
    <InfosWrapperBox>
      <RedBox ref={redBoxRef}>
        <InsideBox>
          {renderLogo()}
          <TextBox>{renderText()}</TextBox>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="closeCross"
            onClick={leave}
          />
        </InsideBox>
      </RedBox>
      <InfosBackdrop onClick={leave}></InfosBackdrop>
    </InfosWrapperBox>
  );
}
