import React from "react";
import {
  BlackBackground,
  StudioCreaSignOff,
  StudioCreaSignOn,
  BrandingSignOff,
  BrandingSignOn,
  EventSignOff,
  EventSignOn,
  DigitalSignOff,
  DigitalSignOn,
} from "@Styles/skills";

import { multipleFlickers } from "../utils";

import InfosWrapper from "./InfosWrapper";
import { useStaticQuery, graphql } from "gatsby";
import { mapDataImages, retrieveImageFluid } from "../utils";

export default function Skills({ visible, isMobile, infosWordings }) {
  let dataImages = useStaticQuery(graphql`
    query SkillsImages {
      allFile(filter: { relativeDirectory: { eq: "skillsScreen" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                base64
                originalImg
                aspectRatio
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
    }
  `);

  dataImages = mapDataImages(dataImages);

  // CONSTS
  const [infos, setInfos] = React.useState(null);
  const studioCreaRef = React.useRef();
  const brandingRef = React.useRef();
  const eventRef = React.useRef();
  const digitalRef = React.useRef();

  // VARIAVBLES

  React.useEffect(() => {
    multipleFlickers({
      element: studioCreaRef.current,
      maxTimes: 3,
      flickerTimeRatio: 50,
      minRestart: 1000,
      maxRestart: 2500,
    });
    multipleFlickers({
      element: brandingRef.current,
      maxTimes: 2,
      flickerTimeRatio: 75,
      minRestart: 2000,
      maxRestart: 3000,
    });
    multipleFlickers({
      element: eventRef.current,
      maxTimes: 4,
      flickerTimeRatio: 50,
      minRestart: 3000,
      maxRestart: 4000,
    });
    multipleFlickers({
      element: digitalRef.current,
      maxTimes: 6,
      flickerTimeRatio: 40,
      minRestart: 3000,
      maxRestart: 5000,
    });
  }, []);

  React.useEffect(() => {
    if (!infos) {
      studioCreaRef.current.style.display = "block";
      brandingRef.current.style.display = "block";
      eventRef.current.style.display = "block";
      digitalRef.current.style.display = "block";
    }
  }, [infos]);

  const onClick = (skill) => {
    switch (skill) {
      case "studioCrea":
        brandingRef.current.style.display = "none";
        eventRef.current.style.display = "none";
        digitalRef.current.style.display = "none";
        setInfos("studioCreatif");
        break;
      case "branding":
        studioCreaRef.current.style.display = "none";
        eventRef.current.style.display = "none";
        digitalRef.current.style.display = "none";
        setInfos("branding");
        break;
      case "event":
        brandingRef.current.style.display = "none";
        digitalRef.current.style.display = "none";
        studioCreaRef.current.style.display = "none";
        setInfos("event");
        break;
      case "digital":
        brandingRef.current.style.display = "none";
        eventRef.current.style.display = "none";
        studioCreaRef.current.style.display = "none";
        setInfos("digital");
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {infos && (
        <InfosWrapper
          skill={infos}
          setInfos={setInfos}
          infosWordings={infosWordings}
        />
      )}
      {isMobile ? (
        <>
          <BlackBackground
            src={retrieveImageFluid(
              dataImages,
              "skills_mobile_background.png",
              true
            )}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <StudioCreaSignOff
            src={retrieveImageFluid(dataImages, "studio_crea_sign.png", true)}
            onClick={() => onClick("studioCrea")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <StudioCreaSignOn
            src={retrieveImageFluid(
              dataImages,
              "studio_crea_sign_on.png",
              true
            )}
            ref={studioCreaRef}
            onClick={() => onClick("studioCrea")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <EventSignOff
            src={retrieveImageFluid(dataImages, "event_sign.png", true)}
            onClick={() => onClick("event")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <EventSignOn
            src={retrieveImageFluid(dataImages, "event_sign_on.png", true)}
            ref={eventRef}
            onClick={() => onClick("event")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <BrandingSignOff
            src={retrieveImageFluid(dataImages, "branding_sign.png", true)}
            onClick={() => onClick("branding")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <BrandingSignOn
            src={retrieveImageFluid(dataImages, "branding_sign_on.png", true)}
            ref={brandingRef}
            onClick={() => onClick("branding")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <DigitalSignOff
            src={retrieveImageFluid(dataImages, "digital_sign.png", true)}
            onClick={() => onClick("digital")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <DigitalSignOn
            src={retrieveImageFluid(dataImages, "digital_sign_on.png", true)}
            ref={digitalRef}
            onClick={() => onClick("digital")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
        </>
      ) : (
        <div style={{ transform: "scale(1.1)", transformOrigin: "top" }}>
          <BlackBackground
            src={retrieveImageFluid(dataImages, "black_background.png", true)}
            isMobile={isMobile}
            alt="Loony skills image"
          />

          {/* STUDIO CREATIF */}
          <StudioCreaSignOff
            src={retrieveImageFluid(dataImages, "studio_crea_sign.png", true)}
            onClick={() => onClick("studioCrea")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <StudioCreaSignOn
            src={retrieveImageFluid(
              dataImages,
              "studio_crea_sign_on.png",
              true
            )}
            ref={studioCreaRef}
            onClick={() => onClick("studioCrea")}
            isMobile={isMobile}
            alt="Loony skills image"
          />

          {/* BRANDING */}
          <BrandingSignOff
            src={retrieveImageFluid(dataImages, "branding_sign.png", true)}
            onClick={() => onClick("branding")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <BrandingSignOn
            src={retrieveImageFluid(dataImages, "branding_sign_on.png", true)}
            ref={brandingRef}
            onClick={() => onClick("branding")}
            isMobile={isMobile}
            alt="Loony skills image"
          />

          {/* EVENT */}
          <EventSignOff
            src={retrieveImageFluid(dataImages, "event_sign.png", true)}
            onClick={() => onClick("event")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <EventSignOn
            src={retrieveImageFluid(dataImages, "event_sign_on.png", true)}
            ref={eventRef}
            onClick={() => onClick("event")}
            isMobile={isMobile}
            alt="Loony skills image"
          />

          {/* DIGITAL */}
          <DigitalSignOff
            src={retrieveImageFluid(dataImages, "digital_sign.png", true)}
            onClick={() => onClick("digital")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
          <DigitalSignOn
            src={retrieveImageFluid(dataImages, "digital_sign_on.png", true)}
            ref={digitalRef}
            onClick={() => onClick("digital")}
            isMobile={isMobile}
            alt="Loony skills image"
          />
        </div>
      )}
    </>
  );
}
