import React from "react";
import { SkillsTextWrapper, BaselineWrapper, BigTexte } from "@Styles/baseline";

const Baseline = React.forwardRef(({ wording }, ref) => {
  return (
    <BaselineWrapper ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        style={{
          backgroundColor: "#0B0B0B",
          position: "relative",
          zIndex: "900",
        }}
      >
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,288L1440,160L1440,320L0,320Z"
        ></path>
      </svg>
      <SkillsTextWrapper>
        {wording.baseline}
        <BigTexte>{wording.subBaseline}</BigTexte>
      </SkillsTextWrapper>
    </BaselineWrapper>
  );
});

export default Baseline;
